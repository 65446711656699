import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import LoadingForButton from "../../../../components/LoadingForButton";
import PromoSelect from "./PromoSelect";
import InputMask from "react-input-mask";

export default function RegistSellForm() {
    const [loading, setLoading] = useState(false)
    const [selectedPromo, setSelectedPromo] = useState({ title: "Selecione uma Promoção" })
    const initialValues = {
        value: "",
        customerPhone: "",
        promotionId: ""
    };

    const validate = (values) => {
        const phoneRegex =
            /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/;

        const errors = {};

        if (!phoneRegex.test(values.customerPhone))
            errors.customerPhone = "Informe um telefone válido";

        if (!(Number(values.value) > 0))
            errors.value = "O valor da compra deve ser maior que 0";

        if (!selectedPromo._id)
            errors.promotionId = "Selecione uma promoção"

        return errors;
    };

    const handleSubmit = async (values, { resetForm }) => {
        const dataToSubmit = {
            value: Number(values.value),
            customerPhone: values.customerPhone.replace(/\D/g, ""),
            promotionId: selectedPromo.id
        };

        try {
            setLoading(true)

            const response = await api.post(
                "/api/v1/loyalty-cards",
                dataToSubmit
            );

            resetForm() // wipe formik values
            setSelectedPromo({ title: "Selecione uma Promoção" }) // wipe the select promo input


            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } catch (error) {
            const { data } = error.response;

            console.log("error", { error });
            toast.error(data?.message, {
                position: "top-right",
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
        finally {
            setLoading(false)
        }
    };

    return (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
            <Form className="flex flex-col items-center justify-center gap-3 w-screen">
                <div className="bg-white rounded-xl shadow-lg p-4 w-11/12 max-w-screen-md mx-3 flex flex-col gap-3">
                    <div>
                        <label htmlFor="promotionId">Promoção</label>
                        <PromoSelect selectedPromo={selectedPromo} setSelectedPromo={setSelectedPromo} />
                        <ErrorMessage name="promotionId" component="div" className="text-red-500" />
                    </div>

                    <div>
                        <label htmlFor="value">Valor em Reais</label>
                        <Field
                            className="form-control"
                            type="number"
                            name="value"
                            placeholder="Valor da Compra"
                            required
                        />
                        <ErrorMessage name="value" component="div" className="text-red-500" />
                    </div>

                    <div>
                        <label htmlFor="customerPhone">Telefone do Cliente</label>
                        <Field
                            className="form-control"
                            as={InputMask}
                            mask="(99) 99999-9999"
                            type="text"
                            name="customerPhone"
                            placeholder="Ex: (99) 9 9999-9999"
                            required
                        />
                        <ErrorMessage name="customerPhone" component="div" className="text-red-500" />
                    </div>

                    <button
                        className="
                        px-5 py-3 mx-auto 
                        text-xl font-semibold
                        rounded-xl bg-primaryGreen
                        w-fit
                        hover:scale-105 duration-300"
                        type="submit"
                    >
                        {loading ? <LoadingForButton /> : "Cadastrar"}
                    </button>
                </div>
            </Form>
        </Formik>
    );
};
